var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMove, Lodash, getBase64FromImageFile, isPlussharpMobileAppAndAndroid } from 'renderer/utils';
import { ProfileCircleContent, TextComponent, Icon, LeftIcon, AntLeftIconButton } from 'renderer/components';
import { COLORS } from 'renderer/styles';
import Dropzone, { useDropzone } from 'react-dropzone';
import { GENDER_ENUM } from 'renderer/models';
import dayjs from 'dayjs';
import { QUERY_CACHE } from 'renderer/queries';
import Row from 'antd/lib/row';
import Upload from 'antd/lib/upload';
import Image from 'antd/lib/image';
import message from 'antd/lib/message';
import styled from 'styled-components';
import { convertHEICToJPG } from 'common-utils/file';
var MAX_IMAGE_LENGTH = 8;
// FIXME: 스타일 시트 추가해서 스타일 클래스 형태로 변경하기
var SortableItem = SortableElement(function (props) {
    var value = props.value, visible = props.visible, onClose = props.onClose, key = props.key, sortIndex = props.sortIndex, onVisibleChange = props.onVisibleChange;
    var isFile = typeof value !== 'string' && !!value;
    var _a = isFile ? React.useState(value) : React.useState(), url = _a[0], setURL = _a[1]; // convert to base64 string
    React.useEffect(function () {
        if (isFile) {
            var reader = new FileReader();
            reader.readAsDataURL(value);
            reader.onloadend = function () {
                setURL([reader.result]);
            };
        }
        else {
            setURL(value);
        }
    }, [value]);
    return (React.createElement(UploaderCell, null,
        sortIndex === 0 && (React.createElement(PrimaryDiv, null,
            React.createElement(TextComponent, { children: "\uB300\uD45C", type: "caption1", color: COLORS.white }))),
        React.createElement(Image, { src: url || value, style: { width: '100%', height: '67px' }, onClick: function () { return onVisibleChange(!visible); } }),
        React.createElement(CloseDiv, { onClick: onClose },
            React.createElement(Icon, { name: "x", color: COLORS.white }))));
});
export var CloseDiv = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  justify-content: center;\n  display: none;\n  width: 16px;\n  height: 16px;\n  position: absolute;\n  top: 4px;\n  right: 4px;\n  border-radius: 2px;\n  background-color: ", ";\n  cursor: pointer;\n  z-index: 1;\n"], ["\n  align-items: center;\n  justify-content: center;\n  display: none;\n  width: 16px;\n  height: 16px;\n  position: absolute;\n  top: 4px;\n  right: 4px;\n  border-radius: 2px;\n  background-color: ", ";\n  cursor: pointer;\n  z-index: 1;\n"])), COLORS.primary1);
/* background-color: ${convertHexToRgbA(COLORS.gray1, 0.5)}; */
export var PrimaryDiv = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-items: center;\n  justify-content: center;\n  display: flex;\n  width: 40px;\n  height: 16px;\n  position: absolute;\n  top: 4px;\n  border-radius: 2px;\n  left: 4px;\n  background-color: ", ";\n  z-index: 1;\n"], ["\n  align-items: center;\n  justify-content: center;\n  display: flex;\n  width: 40px;\n  height: 16px;\n  position: absolute;\n  top: 4px;\n  border-radius: 2px;\n  left: 4px;\n  background-color: ", ";\n  z-index: 1;\n"])), COLORS.primary1);
export var UploaderCell = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  height: 67px;\n  width: calc(25% - 4px);\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  display: flex;\n  border-radius: 2px;\n  border: solid 1px ", ";\n  background-color: ", ";\n  margin-bottom: 12px;\n  margin-right: 4px;\n  cursor: move;\n  & > div {\n    display: flex;\n  }\n"], ["\n  position: relative;\n  height: 67px;\n  width: calc(25% - 4px);\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  display: flex;\n  border-radius: 2px;\n  border: solid 1px ", ";\n  background-color: ", ";\n  margin-bottom: 12px;\n  margin-right: 4px;\n  cursor: move;\n  & > div {\n    display: flex;\n  }\n"])), COLORS.gray6, COLORS.gray7);
var SortableList = SortableContainer(function (_a) {
    var items = _a.items, onClose = _a.onClose, onChange = _a.onChange;
    var _b = React.useState(false), visible = _b[0], setVisible = _b[1];
    return (React.createElement(Row, null,
        items.length < MAX_IMAGE_LENGTH && (React.createElement(DragUploader, { onChange: onChange, defaultItems: items, allowMultiFiles: MAX_IMAGE_LENGTH, accept: isPlussharpMobileAppAndAndroid() ? '.jpg,.jpeg,.png,.heic,.heif,.exe' : 'image/jpg,image/jpeg,image/png,image/heic,image/heif' }, function (_a) {
            var getRootProps = _a.getRootProps, getInputProps = _a.getInputProps;
            return (React.createElement(UploaderCell, __assign({}, getRootProps(), { span: 6 }),
                React.createElement("input", __assign({}, getInputProps())),
                React.createElement(Icon, { name: "camera", size: 32 })));
        })),
        React.createElement(Image.PreviewGroup, { preview: { visible: visible, onVisibleChange: function (visible) { return setVisible(visible); } } }, items.map(function (value, index) { return (React.createElement(SortableItem, { key: index, visible: visible, onVisibleChange: setVisible, sortIndex: index, index: index, value: value, onClose: function () {
                onClose(index);
            } })); }))));
});
var ImageUploader = /** @class */ (function (_super) {
    __extends(ImageUploader, _super);
    function ImageUploader(props) {
        var _this = _super.call(this, props) || this;
        _this.onSortEnd = function (_a) {
            var oldIndex = _a.oldIndex, newIndex = _a.newIndex;
            var newItems = arrayMove(_this.state.items, oldIndex, newIndex);
            _this.setNewItems(newItems);
        };
        _this.state = {
            items: props.defaultItems || []
        };
        return _this;
    }
    ImageUploader.prototype.clear = function () {
        this.setNewItems(this.props.defaultItems || []);
    };
    ImageUploader.prototype.setNewItems = function (items) {
        var _this = this;
        if (items.length > MAX_IMAGE_LENGTH) {
            return alert("\uC774\uBBF8\uC9C0\uB294 ".concat(MAX_IMAGE_LENGTH, "\uAC1C\uAE4C\uC9C0 \uCCA8\uBD80 \uAC00\uB2A5\uD569\uB2C8\uB2E4."));
        }
        this.setState({ items: items }, function () {
            if (_this.props.onChangeIsChanged) {
                _this.props.onChangeIsChanged(!Lodash.isEqual(_this.props.defaultItems, _this.state.items));
            }
            if (_this.props.onChange) {
                _this.props.onChange(_this.state.items);
            }
        });
    };
    ImageUploader.prototype.render = function () {
        var _this = this;
        return (React.createElement(SortableList, { onChange: function (newItem) {
                _this.setNewItems(newItem);
            }, items: this.state.items, onSortEnd: this.onSortEnd, distance: 1, axis: "xy", onClose: function (index) {
                var items = _this.state.items.filter(function (item, itemIndex) { return itemIndex !== index; });
                _this.setNewItems(items);
            } }));
    };
    return ImageUploader;
}(React.Component));
export { ImageUploader };
export var DragUploader = function (props) {
    var _a = React.useState(props.defaultItems), items = _a[0], setItems = _a[1];
    var onDrop = function (acceptedFiles) { return __awaiter(void 0, void 0, void 0, function () {
        var allowMultiFiles, processedFiles, error_1, newItems;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    allowMultiFiles = props.allowMultiFiles;
                    processedFiles = acceptedFiles;
                    // 제한 파일 개수 초과 시 경고
                    if ((items.length || 0) + processedFiles.length > allowMultiFiles) {
                        return [2 /*return*/, alert("".concat(allowMultiFiles, "\uAC1C \uC774\uC0C1\uC758 \uD30C\uC77C\uC744 \uB4F1\uB85D\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4."))];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, Promise.all(processedFiles.map(function (file) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!['image/heic', 'image/heif'].includes(file.type)) return [3 /*break*/, 2];
                                        return [4 /*yield*/, convertHEICToJPG(file)];
                                    case 1: return [2 /*return*/, _a.sent()]; // HEIC -> JPG 변환
                                    case 2: return [2 /*return*/, file]; // HEIC가 아닌 파일은 그대로 반환
                                }
                            });
                        }); }))];
                case 2:
                    processedFiles = _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    return [2 /*return*/, alert('HEIC 파일 변환 중 오류가 발생했습니다.')];
                case 4:
                    newItems = allowMultiFiles
                        ? items.concat(processedFiles) // 기존 파일에 새 파일 추가
                        : processedFiles;
                    setItems(newItems); // 상태 업데이트
                    // onChange 콜백 호출
                    if (props.onChange) {
                        props.onChange(newItems);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    React.useEffect(function () {
        if (!Lodash.isEqual(items, props.defaultItems)) {
            setItems(props.defaultItems);
        }
    }, [props.defaultItems]);
    return (React.createElement(Dropzone, { accept: props.accept, onDrop: onDrop }, props.children
        ? function (_a) {
            var getRootProps = _a.getRootProps, getInputProps = _a.getInputProps;
            return props.children({ getRootProps: getRootProps, getInputProps: getInputProps });
        }
        : function (_a) {
            var getRootProps = _a.getRootProps, getInputProps = _a.getInputProps;
            return (React.createElement("section", null,
                React.createElement("div", __assign({ style: {
                        border: '1px solid lightgrey',
                        width: '100%',
                        height: '100px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    } }, getRootProps()),
                    React.createElement(FileInput, __assign({}, getInputProps(), { files: [], value: '', accept: isPlussharpMobileAppAndAndroid() ? '.jpg,.jpeg,.png,.heic,.heif,.exe' : 'image/jpg,image/jpeg,image/png,image/heic,image/heif', capture: false })),
                    React.createElement("p", null, props.label || ''))));
        }));
};
var FileInput = styled.input(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
export var CustomerExcelUploader = function (props) {
    var _a = useDropzone({
        accept: 'application/vnd.ms-excel,.xlsx',
        maxSize: 1e7,
        onDrop: function (acceptedFiles, fileRejection) { return __awaiter(void 0, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (acceptedFiles.length > 1) {
                            return [2 /*return*/, alert('두개 이상의 파일을 등록할 수 없습니다.')];
                        }
                        if (fileRejection && fileRejection[0] && fileRejection[0].errors) {
                            return [2 /*return*/, alert(fileRejection[0].errors[0])];
                        }
                        if (!props.onChange) return [3 /*break*/, 2];
                        return [4 /*yield*/, getCustomersFromExcel(props.provider, acceptedFiles, props.storeId)];
                    case 1:
                        result = _a.sent();
                        props.onChange(result);
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); }
    }), getRootProps = _a.getRootProps, getInputProps = _a.getInputProps, open = _a.open;
    var getTitle = function () {
        switch (props.provider) {
            case 'ahasoft':
                return '아하소프트 데이터 업로드';
            case 'beatySarang':
                return '뷰티사랑넷 데이터 업로드';
            case 'plusSharp':
                return '엑셀 데이터 데이터 업로드';
        }
    };
    var description = function (text) { return (React.createElement(TextComponent, { color: COLORS.gray3, textAlign: "center" }, text)); };
    var getDescription = function () {
        switch (props.provider) {
            case 'ahasoft':
                return (React.createElement("div", null,
                    description('아하 소프트 고객 데이터를 다운로드 후'),
                    description('업로드 해주세요.')));
            case 'beatySarang':
                return (React.createElement("div", null,
                    description('뷰티 사랑넷 데이터를 다운로드 후'),
                    description('업로드 해주세요.')));
            case 'plusSharp':
                return (React.createElement("div", null,
                    description('아래 양식을 다운로드 후,'),
                    description('고객데이터를 작성해 업로드 해주세요')));
        }
    };
    return (React.createElement("section", null,
        React.createElement(UploaderDiv, __assign({}, getRootProps()),
            React.createElement("input", __assign({}, getInputProps())),
            React.createElement(TextComponent, { type: "body1", color: COLORS.gray1, textAlign: "center", marginBottom: 13 }, getTitle()),
            getDescription()),
        React.createElement(ButtonWrapper, null,
            props.provider === 'plusSharp' && (React.createElement(AntLeftIconButton, { icon: React.createElement(LeftIcon, { name: "download", color: COLORS.primary1 }), type: "primary", ghost: true, onClick: function () {
                    // TODO: Electron Test
                    window.open('https://blockdeal-static-resources.s3.ap-northeast-2.amazonaws.com/plussharp_customer_list.xlsx');
                } }, "\uD30C\uC77C \uB2E4\uC6B4\uB85C\uB4DC")),
            React.createElement(AntLeftIconButton, { type: "primary", ghost: true, icon: React.createElement(LeftIcon, { name: "upload", color: COLORS.primary1 }), onClick: function () {
                    open();
                } }, "\uD30C\uC77C \uC5C5\uB85C\uB4DC"))));
};
var ButtonWrapper = styled(Row)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 12px;\n  width: 100%;\n  margin-left: 0px !important;\n  margin-right: 0px !important;\n  display: flex;\n\n  & > button {\n    flex: 1;\n\n    &:nth-child(2) {\n      margin-left: 10px;\n    }\n  }\n"], ["\n  margin-top: 12px;\n  width: 100%;\n  margin-left: 0px !important;\n  margin-right: 0px !important;\n  display: flex;\n\n  & > button {\n    flex: 1;\n\n    &:nth-child(2) {\n      margin-left: 10px;\n    }\n  }\n"])));
var UploaderDiv = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  flex: 1;\n  display: flex;\n  height: 140px;\n  padding: 20px;\n  border-radius: 2px;\n  border: solid 1px var(--gray-6);\n  background-color: var(--white);\n  alignitems: center;\n  justifycontent: center;\n  flex-direction: column;\n"], ["\n  flex: 1;\n  display: flex;\n  height: 140px;\n  padding: 20px;\n  border-radius: 2px;\n  border: solid 1px var(--gray-6);\n  background-color: var(--white);\n  alignitems: center;\n  justifycontent: center;\n  flex-direction: column;\n"])));
function getCustomersFromExcel(provider, files, storeId) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            // var file = files[0];
            // const buffer = await file.arrayBuffer();
            // var workbook = read(buffer, { type: 'buffer' });
            // const firstSheetName = workbook.SheetNames[0];
            // const dataArray = utils.sheet_to_json(workbook.Sheets[firstSheetName], { raw: false });
            // const getMaker = () => {
            //   switch (provider) {
            //     case 'plusSharp':
            //       return plusSharpMaker;
            //     case 'ahasoft':
            //       return ahasoftMaker;
            //     case 'beatySarang':
            //       return beatySarangMaker;
            //   }
            // };
            // const isValid = (item) => !!item.name || !!item.phone;
            // const customers = getMaker()(dataArray, storeId)
            //   .filter(isValid)
            //   .map((item) => new CustomerModel({ ...item, storeId }));
            // return customers;
            return [2 /*return*/, []];
        });
    });
}
export var plusSharpMaker = function (dataArray, storeId) {
    return dataArray
        .map(function (data) {
        var customer = {};
        Object.keys(data).map(function (key) {
            var value = data[key];
            if (!value || value === '--') {
                return;
            }
            if (key === '고객이름') {
                customer['name'] = value;
            }
            else if (key === '휴대폰') {
                customer['phone'] = formmatPhoneNumber(value);
            }
            else if (key === '생년월일') {
                var birth = dateStringToDate(value.replace('(양)', ''));
                if (birth) {
                    customer['birth'] = birth;
                }
            }
            else if (key === '성별') {
                customer['gender'] = value === '여' ? GENDER_ENUM.FEMALE : GENDER_ENUM.MALE;
            }
            else if (key === '메모') {
                customer['memo'] = value;
            }
            else if (key === '정액권 잔액') {
                // TODO 정액권 잔액
                if (!Number.isNaN(Number(value))) {
                    customer['prepaidAmount'] = Number(value);
                }
            }
            else if (key === '정액권 만료일') {
                customer['prepaidExpiredAt'] = dateStringToDate(value);
                // TODO 정액권 만료일
            }
            else if (key === '담당자') {
                var manager = getManagerFromCacheByName(value, storeId);
                if (manager) {
                    customer['managerId'] = manager.id;
                    customer['manager'] = manager;
                }
            }
            else if (key === '등급명') {
                var customerGroup = getCustomerGroupFromCacheByName(value, storeId);
                if (customerGroup) {
                    customer['customerGroupId'] = customerGroup.id;
                    customer['customerGroup'] = customerGroup;
                }
            }
            else if (key === '마일리지') {
                if (!Number.isNaN(Number(value))) {
                    customer['customerPointAmount'] = Number(value);
                }
                // TODO 마일리지
            }
            else if (key === '주소') {
                customer['address'] = { detailAddress: value };
            }
        });
        return customer;
    })
        .filter(Boolean);
};
var beatySarangMaker = function (dataArray, storeId) {
    return dataArray
        .map(function (data) {
        var customer = {};
        Object.keys(data).map(function (key) {
            var value = data[key];
            if (!value || value === '--') {
                return;
            }
            if (key === '담당자') {
                var manager = getManagerFromCacheByName(value, storeId);
                if (manager) {
                    customer['managerId'] = manager.id;
                    customer['manager'] = manager;
                }
            }
            else if (key === '등록일자') {
                var createdAt = dateStringToDate(value);
                if (createdAt) {
                    customer['createdAt'] = createdAt;
                }
            }
            else if (key === '비고') {
                customer['memo'] = value;
            }
            else if (key === '생년월일') {
                var birth = dateStringToDate(value.replace('(양)', ''));
                if (birth) {
                    customer['birth'] = birth;
                }
            }
            else if (key === '성별') {
                customer['gender'] = value === '여' ? GENDER_ENUM.FEMALE : GENDER_ENUM.MALE;
            }
            else if (key === '이름') {
                customer['name'] = value;
            }
            else if (key === '주소') {
                customer['address'] = { detailAddress: value };
            }
            else if (key === '휴대전화') {
                customer['phone'] = formmatPhoneNumber(value);
            }
            else if (key === '등급') {
                var customerGroup = getCustomerGroupFromCacheByName(value, storeId);
                if (customerGroup) {
                    customer['customerGroupId'] = customerGroup.id;
                    customer['customerGroup'] = customerGroup;
                }
            }
        });
        return customer;
    })
        .filter(Boolean);
};
var ahasoftMaker = function (dataArray, storeId) {
    //   전체고객관리 상세보기: "등록일"
    // __EMPTY: "고객번호"
    // __EMPTY_1: "고객명"
    // __EMPTY_2: "성별"
    // __EMPTY_3: "고객등급"
    // __EMPTY_4: "고객구분"
    // __EMPTY_5: "휴대전화"
    // __EMPTY_6: "휴대전화수신거부"
    // __EMPTY_7: "총매출"
    // __EMPTY_8: "방문횟수"
    // __EMPTY_9: "메모"
    // __EMPTY_10: "담당자"
    // __EMPTY_11: "생일"
    // __EMPTY_12: "결혼기념일"
    // __EMPTY_13: "미수금"
    // __EMPTY_14: "객단가"
    // __EMPTY_15: "전화번호"
    // __EMPTY_16: "잔여포인트"
    // __EMPTY_17: "최근방문일"
    // __EMPTY_18: "우편번호"
    // __EMPTY_19: "주소"
    // __EMPTY_20: "이메일"
    return dataArray
        .map(function (data, index) {
        // if (index < 2) {
        //   return;
        // }
        // console.log('data', data, index);
        var customer = {};
        Object.keys(data).map(function (key) {
            var value = data[key];
            if (!value || value === '--') {
                return;
            }
            // 등록일
            if (key === '전체고객관리 상세보기' || key === '등록일') {
                if (value) {
                    var monthArray = value.split('/');
                    customer['createdAt'] = dayjs("20".concat(monthArray[2], "-").concat(monthArray[0] > 9 ? monthArray[0] : "0".concat(monthArray[0]), "-").concat(monthArray[1])).toDate();
                }
                // 담당자 추가 (이름이 같은 담당자가 있을경우만 넣어주는 로직 추가)
                // 고객번호
            }
            else if (key === '__EMPTY') {
                // 고객명
            }
            else if (key === '__EMPTY_1' || key === '고객명') {
                customer['name'] = value;
                // 성별
            }
            else if (key === '__EMPTY_2' || key === '성별') {
                customer['gender'] = value === '여' ? GENDER_ENUM.FEMALE : GENDER_ENUM.MALE;
                // 고객등급
            }
            else if (key === '__EMPTY_3' || key === '고객등급') {
                var customerGroup = getCustomerGroupFromCacheByName(value, storeId);
                if (customerGroup) {
                    customer['customerGroupId'] = customerGroup.id;
                    customer['customerGroup'] = customerGroup;
                }
                // 고객구분
            }
            else if (key === '__EMPTY_4') {
                // 휴대전화
            }
            else if (key === '__EMPTY_5' || key === '휴대폰') {
                var phone = "".concat(value.startWith === '0' ? '' : '0').concat(value);
                customer['phone'] = formmatPhoneNumber("".concat(phone));
                // 휴대전화수신거부
            }
            else if (key === '__EMPTY_6') {
                // 총매출
            }
            else if (key === '__EMPTY_7') {
                // 방문횟수
            }
            else if (key === '__EMPTY_8') {
                // 메모
            }
            else if (key === '__EMPTY_9' || key === '메모') {
                customer['memo'] = value;
                // 담당자
            }
            else if (key === '__EMPTY_10' || key === '담당자') {
                var manager = getManagerFromCacheByName(value, storeId);
                if (manager) {
                    customer['managerId'] = manager.id;
                    customer['manager'] = manager;
                }
                // 생일
            }
            else if (key === '__EMPTY_11' || key === '생일') {
                if (value) {
                    var monthArray = value.split('/');
                    customer['birth'] = dayjs("19".concat(monthArray[2], "-").concat(monthArray[0] > 9 ? monthArray[0] : "0".concat(monthArray[0]), "-").concat(monthArray[1])).toDate();
                }
                // 결혼기념일
            }
            else if (key === '__EMPTY_12') {
                // TODO: 결혼기념일
                // 미수금
            }
            else if (key === '__EMPTY_13') {
                // TODO: 미수금
                // createUnpaid(value, storeId);
                // 객단가
            }
            else if (key === '__EMPTY_14') {
                // 전화번호
            }
            else if (key === '__EMPTY_15') {
                // TODO: 전화번호
                // 잔여포인트
            }
            else if (key === '__EMPTY_16') {
                // TODO: 포인트 생성
                // createStorePoint(value, storeId);
                if (!Number.isNaN(Number(value))) {
                    customer['customerPointAmount'] = Number(value);
                }
                // 최근방문일
            }
            else if (key === '__EMPTY_17') {
                // 우편번호
            }
            else if (key === '__EMPTY_18') {
                // 주소
            }
            else if (key === '__EMPTY_19' || key === '주소') {
                customer['address'] = { detailAddress: value };
                // 이메일
            }
            else if (key === '__EMPTY_20') {
                // TODO: 이메일
            }
        });
        // console.log('customer', customer);
        return customer;
    })
        .filter(Boolean)
        .reverse();
};
var formmatPhoneNumber = function (phone) {
    var target = phone.replace(/[^0-9]/g, '');
    var formmated = target.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3');
    return formmated;
};
var dateStringToDate = function (birth) {
    var date = dayjs(birth);
    return date.isValid() ? date.toDate() : undefined;
};
// TODO: Point 만들기
var createStorePoint = function (value, storeId) { };
var createUnpaid = function (value, storeId) { };
var getManagerFromCacheByName = function (name, storeId) {
    var managers = QUERY_CACHE.getQueryData(['managers', storeId]);
    var result = managers === null || managers === void 0 ? void 0 : managers.find(function (manager) {
        var _a;
        return ((_a = manager.profile) === null || _a === void 0 ? void 0 : _a.name) === name;
    });
    return result;
};
var getCustomerGroupFromCacheByName = function (name, storeId) {
    var customerGroups = QUERY_CACHE.getQueryData(['customerGroups', storeId]);
    var result = customerGroups === null || customerGroups === void 0 ? void 0 : customerGroups.find(function (group) {
        return group.name === name;
    });
    return result;
};
function beforeUpload(file) {
    var isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    var isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}
export var ProfileDisplayAndUploader = function (props) {
    var _a, _b, _c;
    var manager = props.manager;
    var _d = React.useState(), base64 = _d[0], setBase64 = _d[1];
    var handleChange = function (info) {
        getBase64FromImageFile(info.file.originFileObj, function (imageUrl) { return setBase64(imageUrl); });
        if (props.onChange) {
            props.onChange(info.file.originFileObj);
        }
    };
    React.useEffect(function () { }, [base64]);
    var profilePicture = base64 || (((_a = manager.profile) === null || _a === void 0 ? void 0 : _a.pictures) && ((_b = manager.profile) === null || _b === void 0 ? void 0 : _b.pictures[0]));
    return (React.createElement(ProfileDiv, { manager: manager },
        React.createElement(ProfileCircleContent, { picture: profilePicture, firstChar: (_c = props.manager) === null || _c === void 0 ? void 0 : _c.firstCharInName }),
        React.createElement(StyledUpload, { name: "avatar", listType: "picture-card", className: "avatar-uploader", accept: isPlussharpMobileAppAndAndroid() ? '.jpg,.jpeg,.png,.exe' : 'image/jpg,image/jpeg,image/png', capture: "filesystem", showUploadList: false, beforeUpload: beforeUpload, onChange: handleChange },
            React.createElement(Icon, { name: "edit" }))));
};
export var ProfileDiv = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  background-color: ", ";\n  border-color: ", ";\n  width: 64px;\n  height: 64px;\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n"], ["\n  background-color: ", ";\n  border-color: ", ";\n  width: 64px;\n  height: 64px;\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n"])), function (props) { var _a, _b; return ((_b = (_a = props.manager) === null || _a === void 0 ? void 0 : _a.profile) === null || _b === void 0 ? void 0 : _b.color) || COLORS.white; }, function (props) { var _a, _b; return ((_b = (_a = props.manager) === null || _a === void 0 ? void 0 : _a.profile) === null || _b === void 0 ? void 0 : _b.color) || COLORS.black; });
export var StyledUpload = styled(Upload)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: 30px;\n  height: 30px;\n  position: absolute;\n  right: -8px;\n  bottom: 0px;\n  border-radius: 50%;\n  overflow: hidden;\n  & .ant-upload.ant-upload-select-picture-card {\n    width: 100%;\n    height: 100%;\n    border: solid 1px var(--gray-6);\n    background-color: var(--gray-7);\n  }\n"], ["\n  width: 30px;\n  height: 30px;\n  position: absolute;\n  right: -8px;\n  bottom: 0px;\n  border-radius: 50%;\n  overflow: hidden;\n  & .ant-upload.ant-upload-select-picture-card {\n    width: 100%;\n    height: 100%;\n    border: solid 1px var(--gray-6);\n    background-color: var(--gray-7);\n  }\n"])));
export var ProfileImage = styled.img(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  border-radius: 50%;\n"], ["\n  width: 100%;\n  height: 100%;\n  border-radius: 50%;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
